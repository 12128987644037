import React,{useEffect, useState,useCallback} from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import './PatternLife.css'
import LifeModel from './LifeModel'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BuildPoLicon  from '../../../../images/BuildPoL.png'
import carPoLicon  from '../../../../images/carPoL.png'
import RenameDialog from './RenameDialog'
import APIs from '../../../../Api'
import * as XLSX from 'xlsx';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DetectionPol from './DetectionPoL'


export default function PatternLife({orderImageHandler}) {

    // +++++++++++++++ Pattern Life State +++++++++++++++++++
    let allLifeData = localStorage.getItem('allLife_data') ||  "[]"
    allLifeData = JSON.parse(allLifeData)
    const [patternLifeData_,setPatternLifeData_]  = useState(allLifeData)

    // +++++++++++++++ Detection PoL State +++++++++++++++++++
    let detection_PoL_ = localStorage.getItem('show_detection_pol_model_') || '0'
    let detectionPoL_ = true
    if (detection_PoL_ == '0'){
        detectionPoL_ = false
    }
    const [showDetectionPoL,setShowDetectionPoL]  = useState(detectionPoL_)
    

    // +++++++++++++++ Life Model State +++++++++++++++++++
    let showLifeModel_ = localStorage.getItem('show_life_model_') || '0'
    let show_life_mode = true
    if (showLifeModel_ == '0'){
        show_life_mode = false
    }
    const [showLifeModel,setShowLifeModel]  = useState(show_life_mode)


    // +++++++++++++++ Life Loader State +++++++++++++++++++
    const [showLifeLoader,setLifeLoader]  = useState(false)

    // +++++++++++++++ Life List Option State +++++++++++++++++++
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // +++++++++++++++ Pattern Life Item State +++++++++++++++++++
    const [patternLifeItem, setPatternLifeItem] = useState(false);

    // +++++++++++++++ Rename  State +++++++++++++++++++
    const [openRename, setOpenRename] = useState(false);


    

    useEffect(()=>{
        let getAllLife = localStorage.getItem('get_all_life') || '0'
        if (getAllLife == '0'){
            getAllPatternLife()
            localStorage.setItem('get_all_life',1)
        }
    },[])

    // +++++++++++++++ Search Handler +++++++++++++++++++
    const searchHandler = (e) =>{
        const {value} = e.target
        if (value != '' ||  value != ' '){
            setLifeLoader(true)
            const filteredData  = allLifeData.filter((data,)=>{
                const regex = new RegExp(`${value}`, 'i');
                return regex.test(data.name) 
            })
            setTimeout(()=>{
                setLifeLoader(false)
                setPatternLifeData_(filteredData)
            },600)
            
        } else {
            setPatternLifeData_(allLifeData)
        }
    }

    // +++++++++++++++ Back Handler +++++++++++++++++++
    const backHandler = () =>{
        setShowDetectionPoL(true)
        setShowLifeModel(false)
        localStorage.setItem('show_detection_pol_model_','1')
        localStorage.setItem('show_life_model_','0')
    }


    // +++++++++++++++ Pattern Life List +++++++++++++++++++
    const handleClick = (event,data) => {
        setAnchorEl(event.currentTarget);
        setPatternLifeItem(data)
    };


    // +++++++++++++++ Close Menu +++++++++++++++++++
    const handleClose = () => {
        setAnchorEl(null);
    };

    // +++++++++++++++ Delete Pattern Life +++++++++++++++++++
    const deletePattrenLife = async() => {
        const token = localStorage.getItem('token');
        const headerOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        };
        const response = await fetch(APIs.PLACE_FOOTPRINT+'?id='+patternLifeItem.id, headerOptions);
        await response.json();
        getAllPatternLife()
        setPatternLifeItem(false)
        handleClose()
    };

    // +++++++++++++++ Open Rename Pattren Model Handler  +++++++++++++++++++
    const renamePattren = () =>{
        setOpenRename(true)
        setAnchorEl(null)
    }

    // +++++++++++++++  Download Report Pattren  +++++++++++++++++++
    const downloadReport = () => {
        delete patternLifeItem['id']
        delete patternLifeItem['coordinator']
        delete patternLifeItem['icon_id']
        delete patternLifeItem['user']
        patternLifeItem.start =  patternLifeItem.startDate
        patternLifeItem.end =  patternLifeItem.endDate
        patternLifeItem[patternLifeItem.f_days] =  patternLifeItem.f_value
        patternLifeItem.total =  patternLifeItem.total_object
        patternLifeItem.change =  patternLifeItem.change_object
        patternLifeItem.selected_total =  patternLifeItem.selected_object
        patternLifeItem.selected_change =  patternLifeItem.s_change_object
        patternLifeItem.seletced_status =  patternLifeItem.s_status_object

        delete patternLifeItem['startDate']
        delete patternLifeItem['endDate']
        delete patternLifeItem['f_value']
        delete patternLifeItem['f_days']
        delete patternLifeItem['total_object']
        delete patternLifeItem['change_object']
        delete patternLifeItem['selected_object']
        delete patternLifeItem['s_change_object']
        delete patternLifeItem['s_status_object']
        delete patternLifeItem['graph_change']
    

        let jsonData  = [patternLifeItem]
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `test.xlsx`);  

        if (patternLifeItem['shap_file']){
            let url = APIs.DOMAIN+patternLifeItem['shap_file']
            let file_name = patternLifeItem['shap_file']
            const a = document.createElement('a');
            a.href = url;
            a.download = file_name;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }   
    }

    // +++++++++++++++ Menu +++++++++++++++++++
    const  menu = ()  => {
        return (
            <div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                className='customPopUpMenu'
            >
                <MenuItem onClick={renamePattren}>Rename</MenuItem>
                <MenuItem onClick={deletePattrenLife}>Delete</MenuItem>
                <MenuItem onClick={downloadReport}>Download</MenuItem>
            </Menu>
            </div>
        );
    }

    const allLifeList = () =>{
        return(
            <Box className='life_list_container'>
                <Box className='life_list'>
                    {
                        patternLifeData_.map((item,index)=>{
                            let icon = carPoLicon
                            if (!item.icon_id=='')
                                if (item.icon_id == 'building'){
                                    icon = BuildPoLicon
                                }
                            return(
                                <>
                                    <Box className='life_item'key={index}>
                                        <Box className='life_item_icon'>
                                            <img src={icon} />
                                        </Box> 
                                        <Box className='life_item_name' onClick={()=>{detectionPoLHandler(item)}}>
                                            {item.name}
                                        </Box> 
                                        <Box className='life_item_option' 
                                            onClick={(e)=>{handleClick(e,item)}} 
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                        >
                                            <MoreVertIcon/>
                                        </Box> 
                                    </Box>
                                    <Box className='life_devider'/>
                                </>
                            )
                        })
                        
                    }
                    {menu()}
                </Box>
            </Box>
        )
    }

    const goToLifeModelHandler = () => {
        setShowLifeModel(true)
        localStorage.setItem('show_life_model_','1')
    }

    const getAllPatternLife = async () => {
        const token = localStorage.getItem('token');
        const headerOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
        };
        try {
            const response = await fetch(APIs.PLACE_FOOTPRINT, headerOptions);
            const { data, code, message } = await response.json();
            if (code === 200) {
                localStorage.setItem('allLife_data',JSON.stringify(data))
                setPatternLifeData_(data)
            } else {
                console.log(message, 'Error Messgae');
            }
            
        } catch (error) {
            
        }
        backHandler()
    }

    // +++++++++++++++ Close Rename Pattren Model Handler  +++++++++++++++++++
    const onClose = () => {
        setPatternLifeItem(false)
        setAnchorEl(null)
        setOpenRename(false)
        getAllPatternLife()
    }

    // +++++++++++++++ Show Detection PoL  Model +++++++++++++++++++
    const detectionPoLHandler = (data) => {
        setShowDetectionPoL(false)
        setShowLifeModel(true)
        localStorage.setItem('show_detection_pol_model_','0')
        localStorage.setItem('show_life_model_','1')
        localStorage.setItem('seletced_detectionPoL',JSON.stringify(data))
        setPatternLifeItem(data)
    }



    let seletcedDetectionPoL = localStorage.getItem('seletced_detectionPoL') || "{}"
    seletcedDetectionPoL = JSON.parse(seletcedDetectionPoL)
    return (
        <Box display="flex"  m={1.25}  flexDirection="row">
            <Box mt={0} ml={0.4} className={"containerBox aiModelContainer" + (showLifeModel?" activate_footprint":" ")}>
                {!showLifeModel ? 
                    <>
                        <div className="titleContainer">
                            <span className="title">Pattern of Life</span>
                        </div>
                        <Box className="vaultBox" mt={1}>
                            <Box display="flex" flexDirection="column">
                                <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                                    <span>Search:</span>
                                </Box>
                                <div className="searchBox">
                                    <input
                                    className="searchInput"
                                    type="text"
                                    placeholder="Search"
                                    onChange={(e)=>{searchHandler(e)}}
                                    />
                                    <button className="searchButton">
                                    <svg
                                        className="svgImg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                        d="M14.3333 15.5L9.08333 10.25C8.66667 10.5833 8.1875 10.8472 7.64583 11.0417C7.10417 11.2361 6.52778 11.3333 5.91667 11.3333C4.40278 11.3333 3.12153 10.809 2.07292 9.76042C1.02431 8.71181 0.5 7.43056 0.5 5.91667C0.5 4.40278 1.02431 3.12153 2.07292 2.07292C3.12153 1.02431 4.40278 0.5 5.91667 0.5C7.43056 0.5 8.71181 1.02431 9.76042 2.07292C10.809 3.12153 11.3333 4.40278 11.3333 5.91667C11.3333 6.52778 11.2361 7.10417 11.0417 7.64583C10.8472 8.1875 10.5833 8.66667 10.25 9.08333L15.5 14.3333L14.3333 15.5ZM5.91667 9.66667C6.95833 9.66667 7.84375 9.30208 8.57292 8.57292C9.30208 7.84375 9.66667 6.95833 9.66667 5.91667C9.66667 4.875 9.30208 3.98958 8.57292 3.26042C7.84375 2.53125 6.95833 2.16667 5.91667 2.16667C4.875 2.16667 3.98958 2.53125 3.26042 3.26042C2.53125 3.98958 2.16667 4.875 2.16667 5.91667C2.16667 6.95833 2.53125 7.84375 3.26042 8.57292C3.98958 9.30208 4.875 9.66667 5.91667 9.66667Z"
                                        fill="#0E62E8"
                                        />
                                    </svg>
                                    </button>
                                </div>
                                <Box className={"vaultContainer" + (showLifeLoader ? " vault_loader":"")}>
                                    { showLifeLoader ? 
                                        <Box className="loader_container">
                                            <CircularProgress className="loaderC"/>
                                        </Box> 
                                    :
                                    <>
                                        {allLifeList()}
                                    </>
                                    }
                                </Box>
                                
                            </Box>
                        </Box>
                        <Box className="vaultBox pattrenLife" mt={1}>
                            <Box className="addToCartButton" onClick={()=>{goToLifeModelHandler()}}>Create New</Box>
                        </Box>
                    </>
                : !showDetectionPoL?
                    <>
                        <Box display="flex"mt={0} m={1.25} className='analyzeLife' flexDirection="row">
                        <ArrowBackIosIcon onClick={()=>{backHandler()}} className='backArrow'/>
                            <div className="titleContainer footprint_text">
                                <span className="title">{seletcedDetectionPoL.name}</span>
                            </div>
                        </Box>
                        <DetectionPol data={patternLifeItem}/>
                    </>
                :
                <>  
                    <LifeModel  getAllPatternLife_={getAllPatternLife} orderImageHandler={orderImageHandler} backToLifeHandler_={backHandler}/>
                </>
                }
            </Box>
            { patternLifeItem &&
                <RenameDialog  onClose={onClose} data={patternLifeItem} open={openRename}/>
            }
        </Box>
    )
}
