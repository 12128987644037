import React from 'react'
import {Box,Tabs,Tab,Typography,Grid} from '@material-ui/core';
import ActiveIcon from '../../images/active-world.svg'
import DeactiveIcon from '../../images/deactive-world.svg';
import OrderImageComponent from './screens/OrderImageComponent'; 
import AiModels from './screens/AiModels';
import PatternLife from './screens/patternlife/PatternLife'
import { useSelector, useDispatch } from 'react-redux';
import {analyzeOrderError} from '../../store/actions/auth';

const menu = [
    {
        id:0,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:1,
        modelname:"aiModel",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:2,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:3,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:4,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:5,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:6,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
    {
        id:7,
        modelname:"Order",
        activeIcon:ActiveIcon,
        deactiveIcon:DeactiveIcon
    },
]


export default function TopBar({handler_,analyzeOrderHandler,polygonCoordinates_,updateHeight_}) {
    const [value, setValue] = React.useState(0)
    const [hideArchive, setHideArchive] = React.useState(0)
    const dispatch =  useDispatch()

    const handleChange = (event, newValue) => {
        localStorage.removeItem('ai_contaner_class')
        localStorage.removeItem('aoi_selected_image')
        dispatch(analyzeOrderError(''))
        localStorage.removeItem('archive_coordinator')
        localStorage.setItem('get_all_life','0')
        localStorage.removeItem('footprint_data')
        localStorage.setItem('aoidraw_enable',1)
        handler_(false)
        setValue(newValue);
        if (newValue==1){
            localStorage.setItem('map_zoom','13')
        } else {
            localStorage.setItem('map_zoom','7')
        }
        localStorage.setItem('hide_ai_archive',0)
        setHideArchive(0)
        localStorage.removeItem('ai_model_init')
        localStorage.removeItem('model_active')
        localStorage.removeItem('show_ai_models')
        localStorage.setItem('aoi_selected','select')
        localStorage.setItem('show_footprint_model_','0')
        localStorage.setItem('show_life_model_','0')
    };
    
    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
          <div role="tabpanel" hidden={value !== index} id={`a11y-tabpanel-${index}`} aria-labelledby={`a11y-tab-${index}`} {...other}>
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
          </div>
        );
    }

    const orderImageHandler_ = (data) => {
        handler_(data)
    }

    return (
        <>
        <Box bgcolor="#FDFEFF" borderRadius={15} m={2} width={'fit-content'}>
            <Tabs
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
                value={value}
                onChange={handleChange}
            >
                {
                    menu.map((item_,index)=>{
                        return(
                            <Tab className='top_barg' key={index} icon={ <img width={"38px"} src={ item_.id == value? item_.activeIcon: item_.deactiveIcon}></img>} aria-label="person" />
                        )
                    })
                }
                
            </Tabs>
        </Box>
        <Box>
            <TabPanel value={value} index={0}>
                <OrderImageComponent updateMapHeight={(type)=>{updateHeight_(type)}} polygonCoordinates={polygonCoordinates_} analyzeOrderHandler_={()=>{analyzeOrderHandler()}} orderImageHandler={(data)=>{orderImageHandler_(data)}}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AiModels hideArchive_={hideArchive} analyzeOrderHandler_={()=>{analyzeOrderHandler()}} orderImageHandler={(data)=>{orderImageHandler_(data)}}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PatternLife analyzeOrderHandler_={()=>{analyzeOrderHandler()}} orderImageHandler={(data)=>{orderImageHandler_(data)}}/>
            </TabPanel>
        </Box>
        </>
    )
}
