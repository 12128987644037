import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import './DetectionPoL.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import GraphView from './GraphView';

export default function DetectionPoL({data}) {

    let seletcedDetectionPoL = localStorage.getItem('seletced_detectionPoL') || "{}"
    seletcedDetectionPoL = JSON.parse(seletcedDetectionPoL)
    data = seletcedDetectionPoL

    return (
        <Box className='detection_pol'>          
            <Box className="vaultBox" mt={1}>
                <Box width={"100%"}>
                    <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                        <span>Period:</span>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <input type="date" value={data.startDate} class="dateInputStyle" placeholder="Start"/>
                        <input type="date" value={data.endDate} class="dateInputStyle" placeholder="End" />  
                    </Box>
                </Box>
            </Box>
            <Box className="pol_analysis" >
                <Box className="general_filter analysis_pol">
                    <Typography className='filter_text'>General Filter</Typography>
                    <Box className="vaultBox" mt={1}>
                        <Box width={"100%"}>
                            <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                                <Box className='totalObj_'>
                                    <span>Number of {data.name.split(' ')[0]}</span>
                                    <Box className="totalObj" mb={1} width={"100%"} display={"block"}>
                                        <Box className="total_title">
                                            {data.total_object}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='changeObj_'>
                                    <span>Change %</span>
                                    <Box className="totalObj" mb={1} width={"100%"} display={"block"}>
                                        <Box className={"total_title "+data.status}>
                                            {data.change_object}
                                        </Box>
                                        <Box className="status">
                                            {
                                                data.status == 'up' ?
                                                <ArrowUpwardIcon className={"obj_icon "+data.status}/>
                                                :
                                                <ArrowDownwardIcon className={"obj_icon "+data.status}/>
                                            }   
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
                <Box className="general_filter selected_pol">
                    <Typography className='filter_text'>Selected Area</Typography>
                    <Box className="vaultBox" mt={1}>
                        <Box width={"100%"}>
                            <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                            <Box className='totalObj_'>
                                    <span>Number of {data.name.split(' ')[0]}</span>
                                    <Box className="totalObj" mb={1} width={"100%"} display={"block"}>
                                        <Box className="total_title">
                                            {data.selected_object}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='changeObj_'>
                                    <span>Change %</span>
                                    <Box className="totalObj" mb={1} width={"100%"} display={"block"}>
                                        <Box className={"total_title "+data.s_status_object}>
                                            {data.s_change_object}
                                        </Box>
                                        <Box className="status">
                                            {
                                                data.s_status_object == 'up' ?
                                                <ArrowUpwardIcon className={"obj_icon "+data.s_status_object}/>
                                                :
                                                <ArrowDownwardIcon className={"obj_icon "+data.s_status_object}/>
                                            }   
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="general_filter graph_overview">
                <Typography className='filter_text'>Graph Overview</Typography>
                <Box className="vaultBox" mt={1}>
                    <Box width={"100%"}>
                        <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                            <span>Change:</span>
                        </Box>
                        <Box className="graph_change">
                            <GraphView/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}